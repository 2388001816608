import {
    BatchUploadAdvancedListItemAttachmentCommandInput,
    BatchUploadAdvancedListItemAttachmentCommandOutput,
    ComparisonOperator,
    CreateAdvancedListItemCommandInput,
    CreateAdvancedListItemCommandOutput,
    CreateAdvancedListWorkflowCommandInput,
    CreateAdvancedListWorkflowCommandOutput,
    DeleteAdvancedListItemCommandInput,
    DeleteAdvancedListItemCommandOutput,
    DownloadAdvancedListItemAttachmentCommandInput,
    DownloadAdvancedListItemAttachmentCommandOutput,
    EntityRef,
    EntityWorkflowSearchItem,
    GenInstanceSandboxFileGetUrlCommandInput,
    GenInstanceSandboxFileGetUrlCommandOutput,
    GenInstanceSandboxFilePutUrlCommandInput,
    GenInstanceSandboxFilePutUrlCommandOutput,
    IdentityRef,
    LoadAdvancedListCommandInput,
    LoadAdvancedListCommandOutput,
    LoadAdvancedListItemCommandInput,
    LoadAdvancedListItemCommandOutput,
    SearchAdvancedListItemsCommandInput,
    SearchAdvancedListItemsCommandOutput,
    SearchAdvancedListWorkflowCommandInput,
    SearchAdvancedListWorkflowCommandOutput,
    UpdateAdvancedListItemCommandInput,
    UpdateAdvancedListItemCommandOutput,
    BatchDeleteAdvancedListItemAttachmentCommandInput,
    BatchDeleteAdvancedListItemAttachmentCommandOutput,
    LoadAdvancedListWorkflowCommandInput,
    LoadAdvancedListWorkflowCommandOutput,
    SearchAdvancedListViewsCommandInput,
    CreateAdvancedListViewCommandInput,
    CreateAdvancedListViewCommandOutput,
    DeleteAdvancedListViewCommandInput,
    DeleteAdvancedListViewCommandOutput,
    UpdateAdvancedListViewCommandInput,
    UpdateAdvancedListViewCommandOutput
} from "@amzn/altar-sds-client";
import { APIOutput, IAdvancedList } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface LoadAllAdvancedListWorkflowsCommandInput {
    listRef: EntityRef;
    by: IdentityRef;
}

export interface LoadAllAdvancedListWorkflowsCommandOutput {
    body: EntityWorkflowSearchItem[];
}

export interface AdvancedListAPI {
    load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>>;
    createItem(input: IAdvancedList.CreateAdvancedListItemInput): Promise<APIResponse<CreateAdvancedListItemCommandOutput>>;
    loadItem(input: LoadAdvancedListItemCommandInput): Promise<APIResponse<LoadAdvancedListItemCommandOutput>>;
    deleteItem(input: IAdvancedList.DeleteAdvancedListItemInput): Promise<APIResponse<DeleteAdvancedListItemCommandOutput>>;
    updateItem(input: IAdvancedList.UpdateAdvancedListItemInput): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>>;
    searchItems(input: IAdvancedList.SearchAdvancedListItemInput): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>>;
    batchUploadItemAttachment(
        input: IAdvancedList.BatchUploadAdvancedListItemAttachmentInput
    ): Promise<APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput>>;
    batchDeleteItemAttachment(
        input: BatchDeleteAdvancedListItemAttachmentCommandInput
    ): Promise<APIResponse<BatchDeleteAdvancedListItemAttachmentCommandOutput>>;
    downloadItemAttachment(
        input: DownloadAdvancedListItemAttachmentCommandInput
    ): Promise<APIResponse<DownloadAdvancedListItemAttachmentCommandOutput>>;
    generateSandboxPutUrl(
        input: GenInstanceSandboxFileGetUrlCommandInput
    ): Promise<APIResponse<GenInstanceSandboxFileGetUrlCommandOutput>>;
    generateSandboxPutUrl(
        input: GenInstanceSandboxFilePutUrlCommandInput
    ): Promise<APIResponse<GenInstanceSandboxFilePutUrlCommandOutput>>;
    createWorkflow(
        input: CreateAdvancedListWorkflowCommandInput
    ): Promise<APIResponse<CreateAdvancedListWorkflowCommandOutput>>;
    loadAllAdvancedListWorkflows(
        input: LoadAllAdvancedListWorkflowsCommandInput
    ): Promise<APIResponse<LoadAllAdvancedListWorkflowsCommandOutput>>;
    loadWorkflow(
        input: LoadAdvancedListWorkflowCommandInput
    ): Promise<APIResponse<LoadAdvancedListWorkflowCommandOutput>>;
    searchViews(
        input: SearchAdvancedListViewsCommandInput
    ): Promise<APIResponse<SearchAdvancedListWorkflowCommandOutput>>;
}

export class AdvancedListAPIImpl implements AdvancedListAPI {
    constructor(private context: AppContextValue) {}

    async load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>> {
        const response: APIResponse<LoadAdvancedListCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async createItem(
        input: CreateAdvancedListItemCommandInput
    ): Promise<APIResponse<CreateAdvancedListItemCommandOutput>> {
        const response: APIResponse<CreateAdvancedListItemCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/createItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadItem(input: LoadAdvancedListItemCommandInput): Promise<APIResponse<LoadAdvancedListItemCommandOutput>> {
        const response: APIResponse<LoadAdvancedListItemCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/loadItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async deleteItem(
        input: DeleteAdvancedListItemCommandInput
    ): Promise<APIResponse<DeleteAdvancedListItemCommandOutput>> {
        const response: APIResponse<DeleteAdvancedListItemCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/deleteItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateItem(
        input: UpdateAdvancedListItemCommandInput
    ): Promise<APIResponse<UpdateAdvancedListItemCommandOutput>> {
        const response: APIResponse<UpdateAdvancedListItemCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/updateItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchItems(
        input: SearchAdvancedListItemsCommandInput
    ): Promise<APIResponse<SearchAdvancedListItemsCommandOutput>> {
        const response: APIResponse<SearchAdvancedListItemsCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/searchItems",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async batchUploadItemAttachment(
        input: BatchUploadAdvancedListItemAttachmentCommandInput
    ): Promise<APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput>> {
        const response: APIResponse<BatchUploadAdvancedListItemAttachmentCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/batchUploadAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async batchDeleteItemAttachment(
        input: BatchDeleteAdvancedListItemAttachmentCommandInput
    ): Promise<APIResponse<BatchDeleteAdvancedListItemAttachmentCommandOutput>> {
        const response: APIResponse<BatchDeleteAdvancedListItemAttachmentCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/batchDeleteAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async downloadItemAttachment(
        input: DownloadAdvancedListItemAttachmentCommandInput
    ): Promise<APIResponse<DownloadAdvancedListItemAttachmentCommandOutput>> {
        const response: APIResponse<DownloadAdvancedListItemAttachmentCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/downloadAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async generateSandboxGetUrl(
        input: GenInstanceSandboxFileGetUrlCommandInput
    ): Promise<APIResponse<GenInstanceSandboxFileGetUrlCommandOutput>> {
        const response: APIResponse<GenInstanceSandboxFileGetUrlCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/generateSandboxGetUrl",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async generateSandboxPutUrl(
        input: GenInstanceSandboxFilePutUrlCommandInput
    ): Promise<APIResponse<GenInstanceSandboxFilePutUrlCommandOutput>> {
        const response: APIResponse<GenInstanceSandboxFilePutUrlCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/generateSandboxPutUrl",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async createWorkflow(
        input: CreateAdvancedListWorkflowCommandInput
    ): Promise<APIResponse<CreateAdvancedListWorkflowCommandOutput>> {
        const response: APIResponse<CreateAdvancedListWorkflowCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/createWorkflow",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAllAdvancedListWorkflows(
        input: LoadAllAdvancedListWorkflowsCommandInput
    ): Promise<APIResponse<LoadAllAdvancedListWorkflowsCommandOutput>> {
        const allWorkflows: EntityWorkflowSearchItem[] = [];
        let pageIndex = 1;
        do {
            const searchInput: SearchAdvancedListWorkflowCommandInput = {
                query: {
                    propertyTokens: [
                        {
                            name: "ref.entityExtraRef.entityRef.entityId",
                            value: input.listRef.entityId,
                            operator: ComparisonOperator.EQUALS,
                            isKeywordText: true
                        }
                    ]
                },
                pagination: {
                    pageIndex: pageIndex,
                    pageSize: 100
                },
                by: input.by
            };
            const searchOutput: APIResponse<SearchAdvancedListWorkflowCommandOutput> = await RestAPI.post(
                "custom-domain",
                "/advanced-list/searchAdvancedListWorkflows",
                toDefaultRequestPayload(searchInput)
            );
            const output = APIOutput.fromRaw<SearchAdvancedListWorkflowCommandOutput>(searchOutput.data);
            if (output.isErr()) {
                throw new Error(output.err.message);
            }
            allWorkflows.push(...output.data.items);
            if (output.data.total <= allWorkflows.length) {
                break;
            }
            pageIndex++;
        } while (true);

        const ret: LoadAllAdvancedListWorkflowsCommandOutput = {
            body: allWorkflows
        };

        return {
            statusText: "Success",
            httpStatusCode: 200,
            data: APIOutput.createSuccessOutput(ret)
        };
    }

    async loadWorkflow(
        input: LoadAdvancedListWorkflowCommandInput
    ): Promise<APIResponse<LoadAdvancedListWorkflowCommandOutput>> {
        const response: APIResponse<LoadAdvancedListWorkflowCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/loadAdvancedListWorkflow",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async createView(
        input: CreateAdvancedListViewCommandInput
    ): Promise<APIResponse<CreateAdvancedListViewCommandOutput>> {
        const response: APIResponse<CreateAdvancedListViewCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/createView",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateView(
        input: UpdateAdvancedListViewCommandInput
    ): Promise<APIResponse<UpdateAdvancedListViewCommandOutput>> {
        const response: APIResponse<UpdateAdvancedListViewCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/updateView",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async deleteView(
        input: DeleteAdvancedListViewCommandInput
    ): Promise<APIResponse<DeleteAdvancedListViewCommandOutput>> {
        const response: APIResponse<DeleteAdvancedListViewCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/deleteView",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchViews(
        input: SearchAdvancedListViewsCommandInput
    ): Promise<APIResponse<SearchAdvancedListWorkflowCommandOutput>> {
        const response: APIResponse<SearchAdvancedListWorkflowCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/searchViews",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}