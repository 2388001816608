import * as React from "react";
import {
  FieldDefinitionDataType,
  FieldDefinition,
  Variable
} from "@amzn/altar-sds-client";
import {
  Select,
  SpaceBetween,
  FormFieldProps,
  Header,
} from "@amzn/awsui-components-react";
import { UIField } from "../../../../common/UIField";
import { SmartVariableTextInput } from "./variables/SmartVariableTextInput";
import {
  Condition,
  FieldConfiguration
} from "@amzn/ask-legal-domain";

export interface EmailNotification {
  personFieldKey: string;
  emailSubject: string;
  emailBody: string;
}

export interface EmailNotificationProps {
  fieldDefinitions: FieldDefinition[];
  emailNotification: EmailNotification;
  workflowNotificationType: Condition.Type;
  fieldConfigurations: FieldConfiguration.Record;
  variables: Variable[];
  onChanged?: (emailNotification: EmailNotification) => void;
}

export const EmailNotification = (props: EmailNotificationProps) => {
  const { personFieldKey } = props.emailNotification;
  const emailSubject = convertStorageToDisplayText(props.emailNotification.emailSubject, props.variables, props.fieldDefinitions);
  const emailBody = convertStorageToDisplayText(props.emailNotification.emailBody, props.variables, props.fieldDefinitions);

  const [formFieldPersonFieldKey, setFormFieldPersonFieldKey] =
    React.useState<FormFieldProps>({});
  const [formFieldEmailSubject, setFormFieldEmailSubject] =
    React.useState<FormFieldProps>({});
  const [formFieldEmailBody, setFormFieldEmailBody] =
    React.useState<FormFieldProps>({});

  React.useEffect(() => {
    let isValid = true;

    if (!personFieldKey) {
      setFormFieldPersonFieldKey({
        errorText: "Person field selection is required",
      });
      isValid = false;
    } else {
      setFormFieldPersonFieldKey({
        errorText: "",
      });
    }

    if (!emailSubject.trim()) {
      setFormFieldEmailSubject({
        errorText: "Subject is required",
      });
      isValid = false;
    } else {
      setFormFieldEmailSubject({
        errorText: "",
      });
    }

    if (!emailBody.trim()) {
      setFormFieldEmailBody({
        errorText: "Body is required",
      });
      isValid = false;
    } else {
      setFormFieldEmailBody({
        errorText: "",
      });
    }
  }, [personFieldKey, emailSubject, emailBody]);

  const handlePersonFieldKeyChange = (value: string) => {
    props.onChanged?.(
      { ...props.emailNotification, personFieldKey: value }
    );
  };

  const handleEmailSubjectChange = (value: string) => {
    props.onChanged?.(
      { ...props.emailNotification, emailSubject: value }
    );
  };

  const handleEmailBodyChange = (value: string) => {
    props.onChanged?.(
      { ...props.emailNotification, emailBody: value }
    );
  };

  return (
    <SpaceBetween direction="vertical" size="xxs">
      <Header headingTagOverride="h4">Send Notification</Header>
      <UIField.CustomField
        name="Person"
        description="Please select the person field to whom you want to send the notification"
        errorText={formFieldPersonFieldKey.errorText}
        child={
          <Select
            options={props.fieldDefinitions
              ?.filter(
                (c) => (
                  c.dataType === FieldDefinitionDataType.IdentityRef ||
                  c.dataType === FieldDefinitionDataType.multiIdentityRef
                ) && !c.deprecated
              )
              .map((d) => ({
                value: d.fieldKey,
                label: d.displayName,
              }))}
            selectedOption={{
              value: personFieldKey,
              label: props.fieldDefinitions?.find(
                (f) => f.fieldKey === personFieldKey
              )?.displayName,
            }}
            onChange={(e) =>
              handlePersonFieldKeyChange(e.detail.selectedOption.value!)
            }
          />
        }
      />

      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Include a custom message</Header>
          <UIField.CustomField
            name="Subject"
            errorText={formFieldEmailSubject.errorText}
            child={
              <SmartVariableTextInput
                value={emailSubject}
                variableList={props.variables}
                fieldDefinitions={props.fieldDefinitions}
                onChange={(value) => handleEmailSubjectChange(value)}
              />
            }
          />

          <UIField.CustomField
            name="Body"
            errorText={formFieldEmailBody.errorText}
            child={
              <SmartVariableTextInput
                value={emailBody}
                variableList={props.variables}
                fieldDefinitions={props.fieldDefinitions}
                onChange={(value) => handleEmailBodyChange(value)}
              />
            }
          />
      </SpaceBetween>
    </SpaceBetween>
  );
};


function convertStorageToDisplayText(text: string, variables: Variable[], fieldDefinitions: FieldDefinition[]) {
  const regex = /\$\{context\.([^}]+)\}/g;
  const outputString = text.replace(regex, (match) => {
      const varName = match.split("context.")[1].split("}")[0];
      const foundVariable = variables
          .find(variable => variable.name === varName);
      if (!foundVariable) {
          console.info("no matching var for", match);
          return "";
      }
      const displaySuffix = foundVariable?.value.includes("oldItem") ? "OldValue" : "Value";
      const fieldKey = foundVariable?.value.split("valuesMap['")[1].split("']")[0];
      const fieldDef = fieldDefinitions.find(d => d.fieldKey === fieldKey);
      return `{${fieldDef.displayName}-${displaySuffix}}`;
  });
  if (outputString[0] === "`" && outputString[outputString.length - 1] === "`") {
      return outputString.slice(1, outputString.length - 1);
  } else {
      return outputString;
  }
}