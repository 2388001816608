import {
    ColumnDisplayPreference,
    ConfigurableOption,
    FreshnessState,
    ILegalContact,
    Identity,
    LegalContact,
    WhosMyLawyerContent,
    WhosMyLawyerContentFactory
} from "@amzn/ask-legal-domain";
import {
    Box,
    CollectionPreferencesProps,
    Grid,
    Link,
    PropertyFilterProps,
    SpaceBetween,
    StatusIndicator,
    TableProps
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import { FuzzySearchUtils } from "../../utils/fuzzy-search-utils";
import { CoreUtils } from "../../utils/core-utils";
import "./../../styles/component/legal-contact/view-layout.scss";

export namespace LegalContactsPolarisFactory {
    export namespace Table {
        export const LegalContactUser = Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("legalContactUser")
            .header("Legal Contact")
            .cell(e => <LegalContactUserComp
                    user={e.legalContactUser}
                    jobTitle={e.legalContactJobTitle}
                    badgeColor={e.legalContactBadgeColor}
                />)
            .sortingField("legalContactUser.name")
            .sortingComparator((a, b) => (a.legalContactUser.name > b.legalContactUser.name ? 1 : -1))
            .minWidth("300px")
            .isRowHeader(true)
            .build();

        export const LegalTeams = (options: ConfigurableOption[]) =>  Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("legalTeams")
            .header("Legal Team")
            .cell(e => <WrapCellComp>
                <ConfigurableOptionsListComp
                    optionList={
                        e.legalTeamOptions?.slice(0, 1).map(option => options.find(o => o.id === option.id) || option)
                    }
                />
            </WrapCellComp>)
            .sortingField("legalTeams")
            .sortingComparator((a, b) => (
                a.legalTeamOptions[0]?.displayValue?.toLowerCase() >
                b.legalTeamOptions[0]?.displayValue?.toLowerCase() ?
                1 : -1
            ))
            .minWidth("300px")
            .maxWidth("300px")
            .build();

        export const LegalSubTeams = (options: ConfigurableOption[]) =>  Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("legalSubTeams")
            .header("Legal Sub-Team")
            .cell(e => <WrapCellComp>
                <ConfigurableOptionsListComp
                    optionList={
                        e.legalSubTeamOptions?.map(option => options.find(o => o.id === option.id) || option)
                    }
                />
            </WrapCellComp>)
            .sortingField("legalSubTeams")
            .sortingComparator((a, b) => (
                a.legalSubTeamOptions?.sort((a, b) => (a < b ? 1 : -1))[0]?.displayValue.toLowerCase() >
                b.legalSubTeamOptions?.sort((a, b) => (a < b ? 1 : -1))[0]?.displayValue.toLowerCase()
                ? 1 : -1)
            )
            .minWidth("300px")
            .maxWidth("300px")
            .build();

        export const Manager = Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("manager")
            .header("Manager")
            .cell(e => <Box textAlign="center">
                <UserPhoneToolComp user={e.legalContactManager} />
            </Box>)
            .minWidth("200px")
            .maxWidth("200px")
            .build();

        export const OfficeLocation = Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("officeLocation")
            .header("Office Location")
            .cell(e => <WrapCellComp>
                {e.legalContactOfficeLocation}
            </WrapCellComp>)
            .sortingField("officeLocation")
            .sortingComparator((a, b) => (a.legalContactOfficeLocation > b.legalContactOfficeLocation ? 1 : -1))
            .minWidth("300px")
            .maxWidth("300px")
            .build();

        export const LegalScopes = (options: ConfigurableOption[]) =>  Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("legalScopes")
            .header("Scope")
            .cell(e => <WrapCellComp>
                <ConfigurableOptionsListComp
                    optionList={
                        e.legalScopeOptions?.map(option => {
                            if (option.id.includes(WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX)) return option;
                            return options.find(o => o.id === option.id) || option;
                        })
                    }
                />
            </WrapCellComp>)
            .minWidth("300px")
            .maxWidth("300px")
            .build();

        export const BusinessLeader = Builder<TableProps.ColumnDefinition<LegalContact.Record>>()
            .id("businessLeader")
            .header("Business Leader (Director or VP)")
            .cell(e => <WrapCellComp>
                <BusinessLeaderComp leaders={e.businessLeader} />
            </WrapCellComp>)
            .minWidth("200px")
            .maxWidth("200px")
            .build();

        export const FreshnessStatus = Builder<TableProps.ColumnDefinition<ILegalContact.LoadAllLegalContactsByUserOutput>>()
            .id("freshnessDate")
            .header("Freshness Date (Status)")
            .cell(e => <Box textAlign="center">
                <SpaceBetween size="s" direction="vertical">
                    {DateTimeFormatter.formatDate(e.freshnessDate)}
                    <StatusIndicator type={
                        (e.freshnessStatus === FreshnessState.EXPIRED) ? "error" : "success"
                    }>{e.freshnessStatus}</StatusIndicator>
                </SpaceBetween>
            </Box>)
            .sortingField("freshnessDate")
            .build();

        export function toColumnDefinition(whosMyLawyerContent?: WhosMyLawyerContent) {
            const columnDefinitions = [
                LegalContactUser,
                LegalTeams(whosMyLawyerContent?.dataOptions?.teamOptions || []),
                LegalSubTeams(whosMyLawyerContent?.dataOptions?.subTeamOptions || []),
                LegalScopes(whosMyLawyerContent?.dataOptions?.scopeOptions || []),
                Manager,
                OfficeLocation,
                BusinessLeader
            ];
            return columnDefinitions;
        }

        export function toMyContactsColumnDefinition() {
            return [
                LegalContactUser,
                FreshnessStatus,
                LegalTeams([]),
                LegalSubTeams([]),
                LegalScopes([]),
                Manager,
                OfficeLocation,
                BusinessLeader
            ];
        }

        export function getDefaultColumnDisplay(): ColumnDisplayPreference[] {
            return toColumnDefinition().map(x => ({ id: x.id, visible: true }));
        }

        export function getColumnDisplayOptions(): CollectionPreferencesProps.ContentDisplayOption[] {
            return toColumnDefinition().map(x => ({
                id: x.id,
                label: x.header,
                alwaysVisible: x.isRowHeader
            }));
        }

        export function getSortingColumnById(id: string): TableProps.SortingColumn<LegalContact.Record> {
            const column = toColumnDefinition().find(x => x.id === id);
            if (column) {
                return { sortingField: column.sortingField };
            }
            return DEFAULT_SORTING_STATE.sortingColumn;
        }

        export function toDefaultTablePreferences() {
            return Builder<CollectionPreferencesProps.Preferences<LegalContact.Record>>()
                .pageSize(10)
                .build();
        }
    }

    export namespace Filters {
        const MAX_ERRORS_FUZZY_SEARCH = 2;
        const MIN_SEARCH_QUERY_LENGTH = 3;

        /**
         * Matches item's key object that includes query term in its name, id fields
         */
        function matchNameOrId(query: string, item: LegalContact.Record, key: string) {
            return item[key]?.name.toLowerCase().includes(query.toLowerCase()) ||
                item[key]?.id.toLowerCase().includes(query.toLowerCase());
        }

        /**
         * Apply special attribute filters.
         * Supported attributes - contact, manager
         */
        export function applySpecialAttributeFilter(query: string, item: LegalContact.Record) {
            if (query.includes(":")) {
                const data = query.split(":");
                const value = data[1].trim();
                switch (data[0]) {
                    case "contact":
                        return matchNameOrId(value, item, "legalContactUser");
                    case "manager":
                        return matchNameOrId(value, item, "legalContactManager");
                }
            }
            return false;
        }

        /**
         * Apply fuzzy search with OR capability to query
         */
        export function applyFuzzySearchFilter(query: string, item: LegalContact.Record, fields: string[]) {
            if (query.length < MIN_SEARCH_QUERY_LENGTH) return true;
            const searchTerms = query.toLowerCase().split(" ").filter(x => x.length);
            return FuzzySearchUtils.fuzzySearchOR<LegalContact.Record>(
                item,
                searchTerms,
                fields,
                MAX_ERRORS_FUZZY_SEARCH
            );
        }

        export function applyDefaultFilter(query: string, item: LegalContact.Record, fields: string[]) {
            return fields.some(field => {
                switch (field) {
                    case "legalContactUser":
                    case "legalContactManager":
                        return matchNameOrId(query, item, field);
                    case "businessLeader":
                        return item[field]?.some(x => x.name.toLowerCase().includes(query.toLowerCase()) ||
                            x.id.toLowerCase().includes(query.toLowerCase()));
                    case "legalContactJobTitle":
                    case "legalContactOfficeLocation":
                        return item[field]?.toLowerCase().includes(query.toLowerCase());
                    case "legalScopeOptions":
                    case "legalTeamOptions":
                    case "legalSubTeamOptions":
                        return item[field]?.some(x => x.displayValue.toLowerCase().includes(query.toLowerCase()));
                    default:
                        return JSON.stringify(item[field]).toLowerCase().includes(query.toLowerCase());
                }
            });
        }

        export const defaultFilterFields: string[] = [
            "legalContactUser",
            "legalContactJobTitle",
            "legalTeamOptions",
            "legalSubTeamOptions",
            "legalScopeOptions",
            "legalContactManager",
            "legalContactOfficeLocation",
            "businessLeader"
        ];

        export const fuzzySearchFilterFields: CoreUtils.NestedKeyOf<LegalContact.Record>[] = [
            "legalContactUser.id",
            "legalContactUser.name",
            "legalContactJobTitle",
            "legalTeamOptions[].displayValue",
            "legalSubTeamOptions[].displayValue",
            "legalScopeOptions[].displayValue",
            "legalContactManager.id",
            "legalContactManager.name",
            "legalContactOfficeLocation",
            "businessLeader[].id",
            "businessLeader[].name"
        ];

        export namespace PropertyFilters {
            export const LegalContactUserName = Builder<PropertyFilterProps.FilteringProperty>()
                .key("legalContactUser.name")
                .operators(["=", ":", "!=", "!:"])
                .propertyLabel("Legal Contact Name")
                .build();
            export const LegalContactUserAlias = Builder<PropertyFilterProps.FilteringProperty>()
                .key("legalContactUser.id")
                .operators(["=", ":", "!=", "!:"])
                .propertyLabel("Legal Contact Alias")
                .build();
            export const LegalScopeOptions = Builder<PropertyFilterProps.FilteringProperty>()
                .key("legalScopeOptions.displayValue")
                .operators([":", "!:"])
                .defaultOperator(":")
                .propertyLabel("Legal Scope")
                .build();
            export const LegalTeamOptions = Builder<PropertyFilterProps.FilteringProperty>()
                .key("legalTeamOptions.displayValue")
                .operators([":", "!:"])
                .defaultOperator(":")
                .propertyLabel("Legal Team")
                .build();
            export const LegalSubTeamOptions = Builder<PropertyFilterProps.FilteringProperty>()
                .key("legalSubTeamOptions.displayValue")
                .operators([":", "!:"])
                .defaultOperator(":")
                .propertyLabel("Legal Sub-Team")
                .build();
            export const BusinessLeaderName = Builder<PropertyFilterProps.FilteringProperty>()
                .key("businessLeader.name")
                .operators(["=", ":", "!=", "!:"])
                .propertyLabel("Business Leader Name")
                .build();
            export const BusinessLeaderAlias = Builder<PropertyFilterProps.FilteringProperty>()
                .key("businessLeader.id")
                .operators(["=", ":", "!=", "!:"])
                .propertyLabel("Business Leader Alias")
                .build();

            export function toDefaultFilteringProperties() {
                const filterColumns = [
                    LegalContactUserName,
                    LegalContactUserAlias,
                    LegalTeamOptions,
                    LegalSubTeamOptions,
                    LegalScopeOptions,
                    BusinessLeaderName,
                    BusinessLeaderAlias
                ];
                return filterColumns;
            }
            export function toMyContactsFilteringProperties() {
                return [
                    LegalTeamOptions,
                    LegalSubTeamOptions,
                    LegalScopeOptions,
                    BusinessLeaderName,
                    BusinessLeaderAlias
                ];
            }
        }
    }

    export const DEFAULT_SORTING_STATE: TableProps.SortingState<LegalContact.Record> = {
        sortingColumn: { sortingField: Filters.PropertyFilters.LegalContactUserName.key },
        isDescending: false
    };

    export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<LegalContact.Record> = {
        pageSize: 10,
        contentDisplay: Table.getDefaultColumnDisplay(),
        wrapLines: true,
        stripedRows: false,
        contentDensity: "compact",
        stickyColumns: { first: 1, last: 0 },
    };

    export const DEFAULT_PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
        { value: 5, label: "5 contacts" },
        { value: 10, label: "10 contacts" },
        { value: 25, label: "25 contacts" }
    ];

    function getLegalContactClass(badgeColor: string) {
        switch (badgeColor) {
            case "Blue":
            case "Orange":
            case "Red":
            case "Purple":
            case "Silver":
            case "Gold":
                return `legal-contact-user-${badgeColor.toLowerCase()}`;
            case "White":
            default:
                return undefined;
        }
    }

    const LegalContactUserComp = (props: {
        user: Identity;
        jobTitle?: string;
        badgeColor?: string;
    }) => {
        return <Grid
            className={getLegalContactClass(props.badgeColor)}
            gridDefinition={[
                { colspan: 4 },
                { colspan: 8 }
            ]}
        >
            <img alt={`@${props.user.id} badge photo`} style={{
                height: "100px",
                width: "75px",
                paddingLeft: "2px"
            }} src={`https://badgephotos.corp.amazon.com/?uid=${props.user.id}`} />
            <Box textAlign="center">
                <br />
                <UserPhoneToolComp user={props.user} />
                {props.jobTitle && <>
                    <br />
                    {props.jobTitle}
                </>}
            </Box>
        </Grid>;
    };

    const UserPhoneToolComp = (props: {
        user: Identity;
    }) => {
        if (!props.user?.id) return <span>-</span>;
        return <Link href={`https://phonetool.amazon.com/users/${props.user.id}`}>
            {props.user.name}<br />(<em>{props.user.id}@</em>)
        </Link>;
    };

    const BusinessLeaderComp = (props: {
        leaders: Identity[];
    }) => {
        if (props.leaders.length === 0) return <>-</>;
        else return <SpaceBetween size="xs">
            {props.leaders.map(x => <Box key={x.id} textAlign="center"><UserPhoneToolComp user={x} /></Box>)}
        </SpaceBetween>;
    };

    const WrapCellComp = (props: {
        children: React.ReactNode;
    }) => {
        return <div className="cell-wrapped">
            {props.children}
        </div>;
    };

    const ConfigurableOptionsListComp = (props: {
        optionList: Partial<ConfigurableOption>[];
    }) => {
        if (!props.optionList?.length) return <>-</>;
        return <div className="string-list">
            {props.optionList?.map(x => <span className="string-item">{x.displayValue?.trim() || x.id}</span>)}
        </div>;
    };
}